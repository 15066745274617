
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface registerPass {
  email: any;
  username: string;
  password: string;
}

interface register {
  name: string;
  phone: string;
  referenceFrom: string;
  address: {
    province: string;
    city: string;
    regency: string;
    detail: string
  }
}

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading,
  },
  setup() {
    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;
    
    const store = useStore();
    const router = useRouter();
    const route = useRoute()

    const toast = useToast();

    const registration = Yup.object().shape({
      username: Yup.string().min(4).required('Nama Pengguna Wajib Diisi'),
      password: Yup.string().min(4).required('Kata Sandi Wajib Diisi'),
    });

    const registerPass = reactive<registerPass>({
      email: '',
      username: '',
      password: '',
    });

    const register = reactive<register>({
      name: '',
      phone: '',
      referenceFrom: '',
      address: {
        province: '',
        city: '',
        regency: '',
        detail: '',
      }
    });

    const onSubmitRegister = (values) => {

      isLoading.value = true;
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      ApiService
      .PostMethod('crmv2/main_auth/auth/register/self?username=' + registerPass.username + '&password=' + registerPass.password + '&email=' + registerPass.email, register)
      .then((res) => {
        Swal.fire({
          text: "Terimakasih telah mendaftar, silahkan login kembali menggunakan email atau nama pengguna dan password yang telah dimasukkan",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Baik, saya akan login",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        })
        router.push('/sign-in');
        localStorage.clear();
        isLoading.value = false;
      })
      .catch((e) => {
        toast.error(e.response.data.detail)
        isLoading.value = false;
      });

    };

    onMounted(() => {

      registerPass.email = JSON.parse(localStorage.getItem("register_account")!).email;

      register.name = JSON.parse(localStorage.getItem("register_account")!).name;
      register.phone = JSON.parse(localStorage.getItem("register_account")!).phone;
      register.address.province = JSON.parse(localStorage.getItem("register_account")!).address.province;
      register.address.city = JSON.parse(localStorage.getItem("register_account")!).address.city;
      register.address.regency = JSON.parse(localStorage.getItem("register_account")!).address.regency;
      register.address.detail = JSON.parse(localStorage.getItem("register_account")!).address.detail;

      register.referenceFrom = JSON.parse(localStorage.getItem("register_account")!).referenceFrom;

    });

    return {
      registration,
      onSubmitRegister,
      register,
      registerPass,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
